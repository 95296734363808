import React, { useState, useEffect } from 'react';
import { EnumAction, EventButton, OrderRequirementDetailsItemType } from 'src/api/models';
import { useGetProductById } from 'src/api/productApi';
import { useGetProductSerialById, useSellProductSerial } from 'src/api/productSerialApi';
import useModal from 'src/hooks/useModal';

import { useAddPopup, useReloadTable, useRemovePopup } from 'src/state/application/hooks';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './OrderRequirementPreviewComponent.css';

interface IProductOrderPreviewComponent {
  orderRequirementDetail: OrderRequirementDetailsItemType;
  index: number;
}

const OrderRequirementPreviewComponent: React.FC<IProductOrderPreviewComponent> = (props) => {
  //State
  const [productAvatar, setProductAvatar] = useState(null);
  const [thumbProductAvatar, setThumbProductAvatar] = useState(null);

  const [serialAvatar, setSerialAvatar] = useState(null);
  const [serialThumbAvatar, setSerialThumbAvatar] = useState(null);

  const [productName, setProductName] = useState(null);

  const [price, setPrice] = useState(null);
  const [serialPrice, setSerialPrice] = useState(null);

  const [productSerialId, setProductSerialId] = useState(null);

  const [isShowFullSizeImg, setShowFullSizeImg] = useState(false);

  //Function
  const getProductById = useGetProductById();
  const getProductSerialById = useGetProductSerialById();
  const sellProductSerial = useSellProductSerial();

  const addPopup = useAddPopup();
  const confirmModal = useModal(ConfirmModal);
  const reloadTable = useReloadTable();

  const onSellProductSerial = (productSerialId: number) => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      sellProductSerial(Number(productSerialId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Bán thành công!',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Có gì đó không ổn, kiểm tra lại xem!',
            },
          });
          reloadTable();
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc đổi trạng thái sản phẩm thành đã bán?',
        listActionButton: listToDo,
      },
      'BÁN SẢN PHẨM',
    );
  };

  //useEffect
  useEffect(() => {
    if (props.orderRequirementDetail.productId) {
      getProductById(props.orderRequirementDetail.productId)
        .then((data) => {
          setPrice(data.price);
          setProductName(data.productName);
          setProductAvatar(data.avatar);
          setThumbProductAvatar(data.thumbAvatar);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (props.orderRequirementDetail.productSerialId) {
      getProductSerialById(props.orderRequirementDetail.productSerialId)
        .then((data) => {
          setProductSerialId(data.productSerialId);
          setSerialAvatar(data.avatar);
          setSerialThumbAvatar(data.thumbAvatar);
          setSerialPrice(data.price);
          setProductName(data.productName);
          getProductById(data.productId)
            .then((r) => {
              setPrice(r.price);
              setProductAvatar(r.avatar);
              setThumbProductAvatar(r.thumbAvatar);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    getProductById,
    getProductSerialById,
    props.orderRequirementDetail.productId,
    props.orderRequirementDetail.productSerialId,
  ]);

  //Main
  return (
    <div className={`od-requirement-pre-container ${props.index % 2 == 0 ? 'even' : null}`}>
      <div className="prd-od-pre-child">
        <div
          className="od-pre-img"
          onClick={() => {
            setShowFullSizeImg(true);
          }}
        >
          <img src={serialThumbAvatar ? serialThumbAvatar : thumbProductAvatar} alt="avatar" />
        </div>
      </div>
      <div className="prd-od-pre-child">
        <div>Tên: {productName}</div>
        <div>Số lượng: {props.orderRequirementDetail.quantity}</div>
        <div>Giá: {serialPrice ? serialPrice : price}</div>
        {productSerialId ? (
          <div
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              onSellProductSerial(productSerialId);
            }}
          >
            Bán
          </div>
        ) : null}
      </div>
      {isShowFullSizeImg ? (
        <div className={`pr-od-pre-fullsize-img`}>
          <div
            className="btn-zoom-out-banner-image"
            title="Thu nhỏ"
            onClick={() => {
              setShowFullSizeImg(false);
            }}
          >
            <span className="material-icons">close</span>
          </div>
          <img src={serialAvatar ? serialAvatar : productAvatar} />
        </div>
      ) : null}
    </div>
  );
};

export default OrderRequirementPreviewComponent;
