import './Table.css';
import TableHeader, { TableColumnType } from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { EventButton } from 'src/api/models';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';




export interface ITable<T> {
  header: TableColumnType[];
  data: T[];
  isInput: boolean;

  menuContext: (data: T) => EventButton[];
  onDoubleClick: (...args: any[]) => any;
  onClickCheckbox?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;

  allowCheckbox?: boolean;
  checkedData?: T[],
  isNo?: boolean
}




const Table = <T, >(props: ITable<T>) => {const addPopup = useAddPopup();

  const { 
    header,
    data,
    isInput,  menuContext,
    onDoubleClick,
    onClickCheckbox,
    onClick,  allowCheckbox,
    checkedData,
    isNo
  } = props;

  const [selected, setSelected] = useState<T>();

  const onRightMouseClick = (e: MouseEvent, item: T) => {
    const posX =
      e.clientX >= 0 && e.clientX <= window.innerWidth
        ? e.clientX
        : e.clientX < 0
        ? e.clientX + window.innerWidth
        : e.clientX - window.innerWidth;
    const posY =
      e.clientY >= 0 && e.clientY <= window.innerHeight
        ? e.clientY
        : e.clientY < 0
        ? e.clientY + window.innerHeight
        : e.clientY - window.innerHeight;
    addPopup({
      context: {
        listActionButton: menuContext(item),
        posX: `${posX}px`,
        posY: `${(posY - 60)}px`,
      },
    });
  }

  //Main
  return (
    <div className="table-container">
      <table>
        <thead>
          <TableHeader header={header} allowCheckbox={allowCheckbox} isNo={isNo} />
        </thead>
        <tbody>
          {checkedData? checkedData.map((item: any, index: number) => {
            return (
              <TableRow
                key={`checkedtablerow${index}`}
                header={header} 
                data={item}
                index={index}
                isNo={isNo}
                isShowContext={false}
                onRightMouseClick={onRightMouseClick}
                isInput={isInput}
                onDoubleClick={onDoubleClick}
                onClick={onClick}
                allowCheckbox={allowCheckbox}
                onClickCheckbox={onClickCheckbox}
                onSelect={() => setSelected(item)}
                highlight={item == selected}
                isChecked={true}             
              />
            );
          })
          : null}
          {data? data.map((item: any, index: number)  => {
            return (
              <TableRow
                key={`tablerow${index}`}
                header={header} 
                data={item}
                index={index + (checkedData?.length??0)}
                isNo={isNo}
                isShowContext={false}
                onRightMouseClick={onRightMouseClick}
                isInput={isInput}
                onDoubleClick={onDoubleClick}
                onClick={onClick}
                allowCheckbox={allowCheckbox}
                onClickCheckbox={onClickCheckbox}
                onSelect={() => setSelected(item)}
                highlight={item == selected}
                isChecked={false}             
              />
            );
          })
          : null}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
