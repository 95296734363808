import React from 'react';
import { Navigate } from 'react-router-dom';
import Page from 'src/components/Page';
import useProfile from 'src/hooks/useProfile';

const Profile: React.FC = () => {
  const profile = useProfile();

  return !profile ? (
    <Navigate to="/login" />
  ) : (
    <Page>
      <div>Profile Screen</div>
    </Page>
  );
};

export default Profile;
