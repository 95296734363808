import React, { useCallback, useState } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router';
import { useGetUserInfo, useLoginGoogle, useLoginWithEmail } from 'src/api/backend-api';
import { useInsertFirebaseToken } from 'src/api/firebaseTokenApi';
import Card from 'src/components/Card';
import {
  StyledButton,
  StyledButtonLink,
  StyledCardFooter,
  StyledCardTitle,
} from 'src/components/Form';
import Input from 'src/components/Input';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import { getFCMToken } from 'src/firebase';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup, useSetProfileInfo } from 'src/state/application/hooks';
import './Login.css';
import { Navigate } from 'react-router-dom';

const Login: React.FC = () => {
  const profile = useProfile();
  const addPopup = useAddPopup();
  const navigate = useNavigate();
  const loginWithEmail = useLoginWithEmail();
  const loginGoogle = useLoginGoogle();
  const setProfileInfo = useSetProfileInfo();
  const getUserInfo = useGetUserInfo();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);

  const insertFirebaseToken = useInsertFirebaseToken();

  const onLogin = useCallback(() => {
    //TOTO: Validate
    setLoading(true);
    loginWithEmail(email, password)
      .then((data) => {
        const accessToken = data.token;
        getUserInfo(accessToken)
          .then((profile) => {
            setLoading(false);
            setProfileInfo({
              accessToken: accessToken,
              refreshToken: data.refreshToken,
              info: profile,
            });
            getFCMToken().then((fcmToken: string) => {
              if (fcmToken) {
                insertFirebaseToken(fcmToken, accessToken).then((data) => {
                  console.log(data);
                });
              }
            });
            navigate('/');
          })
          .catch((error) => {
            setLoading(false);
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra!',
              },
            });
          });
      })
      .catch((error) => {
        setLoading(false);
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    loginWithEmail,
    email,
    password,
    getUserInfo,
    insertFirebaseToken,
    setProfileInfo,
    addPopup,
  ]);

  const onLoginGoogle = useCallback(
    (accessToken: string) => {
      if (accessToken) {
        loginGoogle(accessToken)
          .then((data) => {
            const accessToken = data.token;
            getUserInfo(accessToken)
              .then((profile) => {
                setLoading(false);
                setProfileInfo({
                  accessToken: accessToken,
                  refreshToken: data.refreshToken,
                  info: profile,
                });
                // Insert token firebase
                getFCMToken().then((fcmToken: string) => {
                  if (fcmToken) {
                    insertFirebaseToken(fcmToken, accessToken).then((data) => {
                      console.log(data);
                    });
                  }
                });
                navigate('/');
              })
              .catch((error) => {
                setLoading(false);
                addPopup({
                  error: {
                    message: error.errorMessage,
                    title: 'Đã có lỗi xảy ra!',
                  },
                });
              });
          })
          .catch((error) => {
            setLoading(false);
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra!',
              },
            });
          });
      } else {
        addPopup({
          error: {
            message: '',
            title: 'Đã có lỗi xảy ra!',
          },
        });
      }
    },
    [addPopup, getUserInfo, insertFirebaseToken, loginGoogle, navigate, setProfileInfo],
  );

  const clientId = useConfiguration().clientId;
  return profile ? (
    <Navigate to="/" />
  ) : (
    <Page>
      <Card width="450px">
        <StyledCardTitle>Đăng nhập</StyledCardTitle>
        <Input onChange={setEmail} value={email} placeholder="Email" />
        <Input onChange={setPassword} value={password} placeholder="Mật khẩu" />
        <StyledButton onClick={onLogin}>{loading && <Loading />} Đăng nhập</StyledButton>
        <StyledCardFooter>
          Bạn chưa có tài khoản?
          <StyledButtonLink to="/register">Đăng ký ngay</StyledButtonLink>
        </StyledCardFooter>

        <div className="login-google">
          <GoogleOAuthProvider clientId={clientId}>
            {/* <GoogleLogin
              onSuccess={onLoginGoogle}
              onError={() => {
                console.log('Login Failed');
              }}
            /> */}

            <GoogleLoginButton loginGoogle={onLoginGoogle} />
          </GoogleOAuthProvider>
        </div>
      </Card>
    </Page>
  );
};

export default Login;

interface GoogleLoginButtonProp {
  loginGoogle: (accessToken: string) => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProp> = (props) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => props.loginGoogle(tokenResponse.access_token),
  });

  return (
    <>
      <button onClick={() => login()}>Sign in with Google 🚀 </button>
    </>
  );
};
