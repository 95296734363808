import React, { useCallback, useState } from 'react';
import { useNavigate  } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useRegisterWithEmail } from 'src/api/backend-api';
import Card from 'src/components/Card';
import {
  StyledCardTitle,
  StyledButton,
  StyledButtonLink,
  StyledCardFooter,
} from 'src/components/Form';
import Input from 'src/components/Input';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';

const Register: React.FC = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const addPopup = useAddPopup();
  const registerWithEmail = useRegisterWithEmail();
  const [fullName, setFullName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [loginName, setLoginName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onRegister = useCallback(() => {
    setLoading(true);
    // TODO: Validate
    registerWithEmail(email, loginName, fullName, telephone, password, confirmPassword)
      .then(() => {
        setLoading(false);
        navigate(`/verify?loginName=${email}`);
      })
      .catch((error) => {
        setLoading(false);
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      });
  }, [
    addPopup,
    confirmPassword,
    email,
    fullName,
    history,
    loginName,
    password,
    registerWithEmail,
    telephone,
  ]);

  return profile ? (
    <Navigate to="/" />
  ) : (
    <Page>
      <Card width="450px">
        <StyledCardTitle>Đăng ký</StyledCardTitle>
        <Input onChange={setEmail} value={email} placeholder="Email" />
        {/* <Input onChange={setLoginName} placeholder="Tên đăng nhập" /> */}
        <Input onChange={setFullName} value={fullName} placeholder="Họ và tên" />
        <Input onChange={setTelephone} value={telephone} placeholder="Số điện thoại" />
        <Input onChange={setPassword} value={password} placeholder="Mật khẩu" />
        <Input onChange={setConfirmPassword} value={password} placeholder="Xác nhận mật khẩu" />
        <StyledButton isFull onClick={onRegister}>
          {loading && <Loading />}Đăng ký
        </StyledButton>
        <StyledCardFooter>
          Bạn đã có tài khoản?
          <StyledButtonLink to="/login">Đăng nhập ngay</StyledButtonLink>
        </StyledCardFooter>
      </Card>
    </Page>
  );
};

export default Register;
