import { uniqueId } from 'lodash';
import React from 'react';
import './ButtonComponent.css';

interface IButtonComponent {
  width?: string;
  height?: string;
  icon?: string;
  title?: string;
  onClick?: (...args: any[]) => any;
}

const ButtonComponent: React.FC<IButtonComponent> = (props) => {

  const {width, height, icon, title, onClick} = props;

  return (
    <button
      className="button-component"
      onClick={onClick}
    >
      {icon ? <span className="material-icons button-component-content">{icon}</span> : null}<label className='button-component-content'>{title}</label>
    </button>
  );
};

export default ButtonComponent;
