import './ProductSerial.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
  useGetStatusReload,
  useReloadTable,
  useRemovePopup,
} from 'src/state/application/hooks';
import {
  EnumAction,
  EnumDataType,
  EnumProductSerialStatus,
  EventButton,
  ProductAttributeResultType,
  ProductSerialType,
} from 'src/api/models';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ProductSerialDetails from './ProductSerialDetails/ProductSerialDetails';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import {
  useDeleteProductSerial,
  useGetProductSerial,
  useSellProductSerial,
} from 'src/api/productSerialApi';
import { useGetProductById } from 'src/api/productApi';
import ProductSerialExcel from './ProductSerialExcel/ProductSerialExcel';
import { useGetAttributeList } from 'src/api/productCategoryApi';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { FilterType } from 'src/components/FilterBox/FilterOptionBox';
import useDebounce from 'src/hooks/useDebounce';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

//Category
const ProductSerial: React.FC = () => {
  //Value
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  const reloadFlag = useGetStatusReload();
  const navigate = useNavigate();

  //Local state
  const [pageTitle, setPageTitle] = useState<string>();
  const [items, setItems] = useState([]);
  const [keyword, setKeyword] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(50);

  const [count, setCount] = useState<number>();
  const [filters, setFilters] = useState<FilterType<object>[]>([]);
  const [status, setStatus] = useState<number>(EnumProductSerialStatus.stocking);

  const keywordDebound = useDebounce(keyword, 1000);
  const confirmModal = useModal(ConfirmModal);

  const [header, setHeader] = useState<TableColumnType[]>([
    {
      code: 'serial',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Serial',
    },
    {
      code: 'displayOrder',
      dataType: EnumDataType.Int,
      isOptions: false,
      title: 'Thứ tự',
      cellCss: {
        textAlign: 'center',
      },
    },
    {
      code: 'status',
      dataType: EnumDataType.Int,
      isOptions: true,
      title: 'Trạng thái',
      options: [
        {
          title: 'Tồn kho',
          value: EnumProductSerialStatus.stocking,
          css: {
            color: 'blue',
          },
        },
        {
          title: 'Đã bán',
          value: EnumProductSerialStatus.sold,
          css: {
            color: 'red',
          },
        },
      ],
    },
  ]);

  //Function
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();
  const getProductSerial = useGetProductSerial();
  const getProductById = useGetProductById();
  const deleteProductSerial = useDeleteProductSerial();
  const sellProductSerial = useSellProductSerial();
  const reloadTable = useReloadTable();
  const getAttributeList = useGetAttributeList();

  useEffect(() => {
    Promise.all([getAttributeList()]).then(([attributes]) => {
      attributes.forEach((attribute) => {
        header.push({
          code: attribute.attributeName,
          dataType: attribute.attributeType,
          isOptions: false,
          title: attribute.attributeTitle,
        });
      });
      setHeader([...header]);
    });
  }, []);

  useEffect(() => {
    getProductSerial(productId, keyword ?? '', page, size, status)
      .then((data) => {
        setCount(data.count);
        setItems(data.items);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
    getProductById(productId)
      .then((data) => {
        setPageTitle(data.productName);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [reloadFlag, keywordDebound, page, size, productId, status]);

  const menuContext = (item: ProductSerialType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.productSerialId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.productSerialId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.productSerialId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Bán',
      icon: 'storefront',
      actionType: EnumAction.Edit,
      action: () => onSell(item.productSerialId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onView = (productSerialId: number) => {
    addPopup({
      // view: {
      //   width: '85vw',
      //   height: '90vh',
      //   title: 'Chi tiết sản phẩm',
      //   isManualRemove: true,
      //   data: (
      //     <ProductSerialDetails
      //       productId={productId}
      //       isDisable={true}
      //       isAdd={false}
      //       productSerialId={productSerialId}
      //     />
      //   ),
      //   isContext: false,
      // },
    });
  };

  const onEdit = (productSerialId: number) => {
    addPopup({
      // view: {
      //   width: '85vw',
      //   height: '90vh',
      //   title: 'Chỉnh sửa chi tiết',
      //   isManualRemove: true,
      //   data: (
      //     <ProductSerialDetails
      //       productId={productId}
      //       isDisable={false}
      //       isAdd={false}
      //       productSerialId={productSerialId}
      //     />
      //   ),
      //   isContext: false,
      // },
    });
  };

  const onDelete = (productSerialId: number) => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      deleteProductSerial(Number(productSerialId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa sản phẩm thành công!',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
          reloadTable();
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa sản phẩm này?',
        listActionButton: listToDo,
      },
      'XÓA SẢN PHẨM',
    );
  };

  const onSell = (productSerialId: number) => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      sellProductSerial(Number(productSerialId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Bán thành công!',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Có gì đó không ổn, kiểm tra lại xem!',
            },
          });
          reloadTable();
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn bán sản phẩm này?',
        listActionButton: listToDo,
      },
      'BÁN SẢN PHẨM',
    );
  };

  //End of double click

  //Function in the listButton
  const onAddNewProductSerial = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      // addPopup({
      //   view: {
      //     width: '1100px',
      //     height: '600px',
      //     title: 'Thêm serial',
      //     isManualRemove: true,
      //     data: <ProductSerialDetails productId={productId} isDisable={false} isAdd={true} />,
      //     isContext: false,
      //   },
      // });
    }
  };

  const onBackToProduct = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(-1);
    }
  };

  const onOpenPopupProductSerialExcel = () => {
    addPopup({
      // view: {
      //   width: '700px',
      //   height: '100px',
      //   title: 'Excel',
      //   isManualRemove: true,
      //   data: <ProductSerialExcel productId={productId} pageTitle={pageTitle} />,
      //   isContext: false,
      // },
    });
  };
  //End of function in the listbutton

  // List of buttons in toolbar
  const listButtonRight: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 sim-order-tool-btn',
      action: onBackToProduct,
      align: 'center',
    },
    {
      name: 'Thêm serial',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info sim-order-tool-btn',
      action: onAddNewProductSerial,
      align: 'center',
    },
    {
      name: 'Excel',
      icon: 'view_list',
      actionType: EnumAction.View,
      buttonClass: 'info100 sim-order-tool-btn',
      action: onOpenPopupProductSerialExcel,
      align: 'center',
    },
  ];

  const onFilter = (res: { [filterKey: string]: any }) => {
    console.log('Filter');
  };

  //Main
  return (
    <>
      <ToolBar
        toolbarName={`Chi tiết tồn kho/${pageTitle}`}
        listRightButton={listButtonRight}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />
      <Table
        header={header}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.productSerialId)}
      />
    </>
  );
};

export default ProductSerial;
