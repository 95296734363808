import './User.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
  useGetStatusReload,
  useReloadTable,
  useRemovePopup,
} from 'src/state/application/hooks';
import { EnumAction, EventButton, ProfileInfo } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import { useDeleteUser, useGetUserList } from 'src/api/userApi';
import UserDetail, { EnumUserDetailConfig } from './UserDetail/UserDetail';
import UserSlideBar from './UserSlideBar/UserSlideBar';

// window.addEventListener('contextmenu', (e) => e.preventDefault());

const User: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();
  const reloadFlag = useGetStatusReload();
  const reloadTable = useReloadTable();
  const getUserList = useGetUserList();
  const deleteUser = useDeleteUser();

  //Local state
  const [keyword, setKeyword] = useState<string>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(50);
  const [count, setCount] = useState<number>(null);
  const [userList, setUserList] = useState<ProfileInfo[]>([]);

  //Define
  const LEFT_MOUSE_BUTTON = 0;
  const displayList: string[] = ['.fullName', '.email'];
  const header: string[] = ['Tên', 'Email'];
  const typeList: string[] = ['string', 'string'];
  //End of define

  //Menucontext
  const onView = (userId: number) => {
    addPopup({
      // view: {
      //   width: '400px',
      //   height: '175px',
      //   title: 'Chi tiết tài khoản',
      //   isManualRemove: true,
      //   data: <UserDetail config={EnumUserDetailConfig.view} userId={userId} />,
      //   isContext: false,
      // },
    });
  };

  const onEdit = (userId: number) => {
    addPopup({
      // view: {
      //   width: '400px',
      //   height: '250px',
      //   title: 'Chỉnh sửa chi tiết',
      //   isManualRemove: true,
      //   data: <UserDetail config={EnumUserDetailConfig.edit} userId={userId} />,
      //   isContext: false,
      // },
    });
  };

  const onChangePassword = (userId: number) => {
    addPopup({
      // view: {
      //   width: '400px',
      //   height: '300px',
      //   title: 'Đổi mật khẩu',
      //   isManualRemove: true,
      //   data: <UserDetail config={EnumUserDetailConfig.change_pass} userId={userId} />,
      //   isContext: false,
      // },
    });
  };

  const onDelete = (userId: number) => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      deleteUser(Number(userId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa tài khoản thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
          reloadTable();
        });
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    // addPopup({
    //   confirm: {
    //     width: '400px',
    //     height: '150px',
    //     question: 'Bạn có chắc muốn xóa tài khoản này?',
    //     listActionButton: listButton,
    //   },
    // });
  };

  //When click right mouse
  const onRightMouseClick = (e: MouseEvent, index: number, userId: number) => {
    const RIGHT_MOUSE_BUTTON = 2;
    const listToDo: EventButton[] = [
      {
        name: 'Chi tiết',
        icon: 'info',
        actionType: EnumAction.Edit,
        action: () => onView(userId),
        buttonClass: 'info',
        align: 'left',
      },
      {
        name: 'Sửa',
        icon: 'auto_fix_high',
        actionType: EnumAction.Edit,
        action: () => onEdit(userId),
        buttonClass: 'info',
        align: '',
      },
      {
        name: 'Đổi mật khẩu',
        icon: 'vpn_key',
        actionType: EnumAction.Edit,
        action: () => onChangePassword(userId),
        buttonClass: 'info',
        align: '',
      },
      {
        name: 'Xóa',
        icon: 'delete',
        actionType: EnumAction.Edit,
        action: () => onDelete(userId),
        buttonClass: 'info',
        align: '',
      },
    ];

    if (e.button === RIGHT_MOUSE_BUTTON) {
      //hiddenSLideBar();
      const posX =
        e.clientX >= 0 && e.clientX <= window.innerWidth
          ? e.clientX
          : e.clientX < 0
          ? e.clientX + window.innerWidth
          : e.clientX - window.innerWidth;
      const posY =
        e.clientY >= 0 && e.clientY <= window.innerHeight
          ? e.clientY
          : e.clientY < 0
          ? e.clientY + window.innerHeight
          : e.clientY - window.innerHeight;
      addPopup({
        context: {
          width: '160px',
          height: '170px',
          listActionButton: listToDo,
          posX: `${posX.toString()}px`,
          posY: `${(posY - 60).toString()}px`,
        },
      });
    }
  };
  //End of menucontext

  const onDoubleClick = (userId: number) => {
    onView(userId);
    //hiddenSLideBar();
  };

  const onClick = (userId: number) => {
    //showSlideBar();
    //setSlideBarContent({
    // view: {
    //   width: '',
    //   height: '100%',
    //   title: '',
    //   isManualRemove: true,
    //   data: <UserSlideBar key={Math.random()} userId={userId} />,
    //   isContext: false,
    // },
    //});
  };

  //Function in the listButton
  const onAddNewUser = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      //hiddenSLideBar();
      addPopup({
        // view: {
        //   width: '400px',
        //   height: '350px',
        //   title: 'Thêm tài khoản mới',
        //   isManualRemove: true,
        //   data: <UserDetail config={EnumUserDetailConfig.add} />,
        //   isContext: false,
        // },
      });
    }
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 sim-order-tool-btn',
      action: onAddNewUser,
      align: 'center',
    },
  ];

  const onChangeToolBar = (name: string, _value: string) => {
    if (name === 'keyword') {
      setKeyword(_value);
      setPage(1);
    } else if (name === 'page') {
      setPage(Number(_value));
    } else if (name === 'size') {
      setSize(Number(_value));
      setPage(1);
    }
  };
  //End of toolbar

  useEffect(() => {
    getUserList(keyword)
      .then((data) => {
        setCount(data.count);
        setUserList(data.items);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getUserList, keyword, reloadFlag]);

  return (
    <div className="product-container">
      {/* <ToolBar
        toolbarName={'Danh sách tài khoản'}
        listRightButton={listButton}
        width={'100%'}
        height={'50px'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onChangeToolBar={onChangeToolBar}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        listCheckbox={[]}
      /> */}
      {/* <Table
        key={'table user'}
        header={header}
        tableType={'label'}
        isDisable={true}
        data={userList}
        onRightMouseClick={onRightMouseClick}
        displayList={displayList}
        manage={'.userId'}
        typeList={typeList}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
      /> */}
    </div>
  );
};
export default User;
