import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetShipInfoByZipcode } from 'src/api/commonInfomationApi';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  EnumReceiveTime,
  EnumReceiveTimeTitle,
  EventButton,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
  ProfileInfo,
} from 'src/api/models';
import {
  useGetOrderRequirementById,
  usePutOrderRequirement,
} from 'src/api/orderRequirementApi';
import { useGetQRCode } from 'src/api/qrApi';
import { useGetUserList } from 'src/api/userApi';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import OrderRequirementPreviewComponent from 'src/components/OrderRequirementPreviewComponent/OrderRequirementPreviewComponent';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { BASE_WEB_URL } from 'src/constants';
import {
  useAddPopup,
  useGetStatusReload,
  useReloadTable,
  useRemovePopup,
} from 'src/state/application/hooks';
import OrderRequirementTabSlide from '../../../components/Note/Note';
import './OrderRequirementDetail.css';
import { normalizationZipcode } from 'src/utils/stringUtils';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import Note from '../../../components/Note/Note';
import ContactList from 'src/components/ContactList/ContactList';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IOrderRequirementDetail {
  orderRequirementId?: number;
  isDisable?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const OrderRequirementDetail: React.FC<IOrderRequirementDetail> = (props) => {
  const lstStatus = [
    {
      value: EnumOrderRequirementProgressStatus.Waiting,
      title: EnumOrderRequirementProgressStatusTitle.Waiting,
      css: { color: '#3a3131' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Sent,
      title: EnumOrderRequirementProgressStatusTitle.Sent,
      css: { color: 'blue' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Received,
      title: EnumOrderRequirementProgressStatusTitle.Received,
      css: { color: 'green' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Error,
      title: EnumOrderRequirementProgressStatusTitle.Error,
      css: { color: 'red' },
    },
  ];

  const params = useParams<{ type: string; orderRequirementId: string }>();
  const config = params.type;
  const [orderRequirementId, setOrderRequirementId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);

  const navigate = props.isSlide ? undefined : useNavigate();

  const [createdAt, setCreateAt] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [userIdError, setUserIdError] = useState<string>();
  const [receiverFullname, setReceiverFullname] = useState<string>();
  const [receiverFullnameError, setReceiverFullnameError] = useState<string>();
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>();
  const [receiverZipCode, setReceiverZipCode] = useState<string>();
  const [receiverZipCodeError, setReceiverZipCodeError] = useState<string>();
  const [receiverAddress1, setReceiverAddress1] = useState<string>();
  const [receiverAddress2, setReceiverAddress2] = useState<string>();
  const [receiverAddress3, setReceiverAddress3] = useState<string>();
  const [receiverAddress4, setReceiverAddress4] = useState<string>();
  const [receiverFacebook, setReceiverFacebook] = useState<string>();
  const [receiverZalo, setReceiverZalo] = useState<string>();
  const [trackingCode, setTrackingCode] = useState<string>();
  const [orderRequirementNote, setOrderRequirementNote] = useState<string>();
  const [shipFee, setShipFee] = useState<number>();
  const [discount, setDiscount] = useState<number>();
  const [receiverTime1, setReceiverTime1] = useState<string>();
  const [receiverTime2, setReceiverTime2] = useState<string>();

  const [progressStatus, setProgressStatus] = useState<EnumOrderRequirementProgressStatus>(
    EnumOrderRequirementProgressStatus.Waiting,
  );

  const [orderRequirementDetail, setOrderRequirementDetail] = useState<
    OrderRequirementDetailsItemType[]
  >([]);
  const [userName, setUserName] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);

  //End of state

  //Function
  const addPopup = useAddPopup();
  const getUserList = useGetUserList();
  const getOrderRequirementById = useGetOrderRequirementById();
  const putOrderRequirement = usePutOrderRequirement();

  useEffect(() => {
    setOrderRequirementId(props.orderRequirementId || Number(params.orderRequirementId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [props.orderRequirementId, params.orderRequirementId, props.isDisable, params.type]);

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_WEB_URL}/order-requirement/${orderRequirementId}/${EnumViewType.Edit}`);
  };

  const onBack = () => {
    navigate(-1);
  };

  //Validate
  const validateReceiverFullname = () => {
    setReceiverFullnameError(
      receiverFullname && receiverFullname != '' ? undefined : 'Chưa nhập tên người nhận hàng',
    );
    return receiverFullname && receiverFullname != '';
  };

  const validateReceiverZipcode = () => {
    setReceiverZipCodeError(
      receiverZipCode && receiverZipCode != '' ? undefined : 'Chưa nhập mã bưu điện',
    );
    return receiverZipCode && receiverZipCode != '';
  };

  const calcTotal = () => {
    let sum = 0;
    if (orderRequirementDetail) {
      orderRequirementDetail.forEach((detail) => {
        const price = detail.productSerialId
          ? detail.productSerial?.price
          : detail.product?.price;
        sum += price ?? 0 * detail.quantity ?? 0;
      });
    }
    sum += shipFee ?? 0;
    sum -= discount ?? 0;
    return sum;
  };

  //Validate
  const validateUserId = () => {
    let isContinue = true;

    if (!userId) {
      isContinue = false;
      setUserIdError('Chưa nhập tên người gửi');
    } else setUserIdError(null);

    return isContinue;
  };

  //TODO
  const onPutOrderRequirement = () => {
    const isReceiverFullname = validateReceiverFullname();
    const isReceiverZipcode = validateReceiverZipcode();
    const isUserId = validateUserId();
    if (isReceiverFullname && isUserId && isReceiverZipcode && orderRequirementId) {
      const orderRequirement: OrderRequirementType = {
        orderRequirementId: orderRequirementId,
        userId: userId,
        receiverFullname: receiverFullname,
        receiverPhoneNumber: receiverPhoneNumber,
        receiverZipCode: receiverZipCode,
        receiverAddress1: receiverAddress1,
        receiverAddress2: receiverAddress2,
        receiverAddress3: receiverAddress3,
        receiverAddress4: receiverAddress4,
        receiverFacebook: receiverFacebook,
        receiverZalo: receiverZalo,
        shipFee: shipFee,
        trackingCode: trackingCode,
        orderRequirementNote: orderRequirementNote,
        progressStatus: progressStatus,
        orderRequirementDetails: orderRequirementDetail,
      };
      putOrderRequirement(orderRequirement)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Sửa đơn thành công',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  const getQRCode = useGetQRCode();
  const onShowQRCode = (url: string) => {
    getQRCode({
      content: url,
    })
      .then((blob: Blob) => {
        console.log(blob);
        addPopup({
          // view: {
          //   title: 'QR Code',
          //   isManualRemove: true,
          //   data: (
          //     <div className="qr-popup">
          //       <img src={URL.createObjectURL(blob)} />
          //     </div>
          //   ),
          //   isContext: false,
          // },
        });
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      });
  };

  const openLink = (url: string) => {
    window.open(url);
  };

  useEffect(() => {
    if (orderRequirementId) {
      getOrderRequirementById(orderRequirementId)
        .then((data) => {
          setProgressStatus(data.progressStatus);
          setCreateAt(data.createdAt);
          setUserId(data.userId);
          setUserName(data.userName);
          setOrderRequirementDetail(data.orderRequirementDetails);
          setReceiverFullname(data.receiverFullname);
          setReceiverPhoneNumber(data.receiverPhoneNumber);
          setReceiverFacebook(data.receiverFacebook);
          setReceiverZalo(data.receiverZalo);
          setReceiverZipCode(data.receiverZipCode);
          setReceiverAddress1(data.receiverAddress1);
          setReceiverAddress2(data.receiverAddress2);
          setReceiverAddress3(data.receiverAddress3);
          setReceiverAddress4(data.receiverAddress4);
          setShipFee(data.shipFee);
          setDiscount(data.discount);
          setTrackingCode(data.trackingCode);
          setOrderRequirementNote(data.orderRequirementNote);

          switch (data.receiverTime1) {
            case EnumReceiveTime.Any: {
              setReceiverTime1(`${EnumReceiveTimeTitle.Any} ${data.receiverDate1}`);
              break;
            }
            case EnumReceiveTime.Morning: {
              setReceiverTime1(`${EnumReceiveTimeTitle.Morning} ${data.receiverDate1}`);
              break;
            }
            case EnumReceiveTime.Twelve_Fifteen: {
              setReceiverTime1(`${EnumReceiveTimeTitle.Twelve_Fifteen} ${data.receiverDate1}`);
              break;
            }
            case EnumReceiveTime.Fifteen_Eightteen: {
              setReceiverTime1(
                `${EnumReceiveTimeTitle.Fifteen_Eightteen} ${data.receiverDate1}`,
              );
              break;
            }
            case EnumReceiveTime.Eightteen_TwentyOne: {
              setReceiverTime1(
                `${EnumReceiveTimeTitle.Eightteen_TwentyOne} ${data.receiverDate1}`,
              );
              break;
            }
          }

          switch (data.receiverTime2) {
            case EnumReceiveTime.Any: {
              setReceiverTime2(`${EnumReceiveTimeTitle.Any} ${data.receiverDate2}`);
              break;
            }
            case EnumReceiveTime.Morning: {
              setReceiverTime2(`${EnumReceiveTimeTitle.Morning} ${data.receiverDate2}`);
              break;
            }
            case EnumReceiveTime.Twelve_Fifteen: {
              setReceiverTime2(`${EnumReceiveTimeTitle.Twelve_Fifteen} ${data.receiverDate2}`);
              break;
            }
            case EnumReceiveTime.Fifteen_Eightteen: {
              setReceiverTime2(
                `${EnumReceiveTimeTitle.Fifteen_Eightteen} ${data.receiverDate2}`,
              );
              break;
            }
            case EnumReceiveTime.Eightteen_TwentyOne: {
              setReceiverTime2(
                `${EnumReceiveTimeTitle.Eightteen_TwentyOne} ${data.receiverDate2}`,
              );
              break;
            }
          }
        })
        .catch((error) => {
          console.log('order requirement tab error');
          console.log(error);
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getOrderRequirementById, orderRequirementId]);

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info sim-order-tool-btn',
      action: onBack,
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.View,
      buttonClass: 'info100 sim-order-tool-btn',
      action: onChangeEditMode,
      align: 'center',
    },
  ];

  const listButtonDis: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info sim-order-tool-btn',
      action: onBack,
      align: 'center',
    },
  ];

  useEffect(() => {
    const handleClick = (event: any) => {
      const contactPopup = document.getElementById('contact-popup');
      const btnShowContact = document.getElementById('show-contact-icon');
      if (
        btnShowContact &&
        contactPopup &&
        !btnShowContact.contains(event.target) &&
        !contactPopup.contains(event.target)
      ) {
        setIsShowUserList(false);
      }
    };

    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  //Main
  return (
    <>
      {props.isSlide ? null : (
        <ToolBar
          toolbarName={`Đơn hàng ${userName} - ${createdAt}`}
          listRightButton={isDisable ? listButton : listButtonDis}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
        />
      )}
      <div className="order-requirement-container">
        <div className="order-requirement-info">
          <div className="order-requirement-row">
            <Input
              title="Người đặt hàng"
              require={true}
              disabled={isDisable}
              value={userName}
            />
            {!isDisable && (
              <div className="i-tooltip guide">
                <span
                  className="material-icons info-guide"
                  onClick={() => setIsShowUserList(true)}
                  id="show-contact-icon"
                >
                  account_circle
                </span>
                <span className="tooltiptext">Chọn người đặt hàng</span>
              </div>
            )}
            {isShowUserList ? (
              <ContactList
                onSelect={(userId: number) => {
                  setIsShowUserList(false);
                }}
              />
            ) : null}
          </div>
          <div className="order-requirement-row">
            <Input
              title="Người nhận"
              require={true}
              disabled={isDisable}
              value={receiverFullname}
              onChange={setReceiverFullname}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="SĐT người nhận"
              require={true}
              disabled={isDisable}
              value={receiverPhoneNumber}
              onChange={setReceiverPhoneNumber}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Facebook"
              require={true}
              disabled={isDisable}
              value={receiverFacebook}
              onChange={setReceiverFacebook}
              inputPadding="3px 10px 3px 3px"
            />
            <div className="i-tooltip guide">
              <span
                className="material-icons info-guide"
                onClick={() => openLink(receiverFacebook)}
              >
                launch
              </span>
              <span className="tooltiptext">Truy cập Facebook</span>
            </div>
          </div>
          <div className="order-requirement-row">
            <Input
              title="Zalo"
              require={true}
              disabled={isDisable}
              value={receiverZalo}
              onChange={setReceiverZalo}
              inputPadding="3px 30px 3px 3px"
            />
            <div className="i-tooltip qr-scan">
              <span
                className="material-icons qr-scan-icon"
                onClick={() => onShowQRCode(receiverZalo)}
              >
                qr_code
              </span>
              <span className="tooltiptext">Hiển thị QRCode thông tin Zalo</span>
            </div>
            <div className="i-tooltip guide">
              <span
                className="material-icons info-guide"
                onClick={() => openLink(receiverZalo)}
              >
                launch
              </span>
              <span className="tooltiptext">Truy cập Zalo</span>
            </div>
          </div>
          <div className="order-requirement-row">
            <Input
              title="Mã vận đơn"
              require={true}
              disabled={isDisable}
              value={trackingCode}
              onChange={setTrackingCode}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Thời gian nhận hàng 1"
              require={true}
              disabled={isDisable}
              value={receiverTime1}
              onChange={setReceiverTime1}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Thời gian nhận hàng 2"
              require={true}
              disabled={isDisable}
              value={receiverTime2}
              onChange={setReceiverTime2}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Mã bưu điện"
              require={true}
              disabled={isDisable}
              value={receiverZipCode}
              onChange={setReceiverZipCode}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Địa chỉ dòng 1"
              require={true}
              disabled={isDisable}
              value={receiverAddress1}
              onChange={setReceiverAddress1}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Địa chỉ dòng 2"
              require={true}
              disabled={isDisable}
              value={receiverAddress2}
              onChange={setReceiverAddress2}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Địa chỉ dòng 3"
              require={true}
              disabled={isDisable}
              value={receiverAddress3}
              onChange={setReceiverAddress3}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Địa chỉ dòng 4"
              require={true}
              disabled={isDisable}
              value={receiverAddress4}
              onChange={setReceiverAddress4}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              title="Ghi chú khách hàng"
              require={true}
              disabled={isDisable}
              value={orderRequirementNote}
              onChange={setOrderRequirementNote}
            />
          </div>
        </div>
        <div className="order-requirement-details">
          <div className="add-order-title">Danh sách sản phẩm</div>
          {orderRequirementDetail
            ? orderRequirementDetail.map((value, index) => {
                return (
                  <OrderRequirementPreviewComponent
                    key={`orderrequirementdetail${index}`}
                    orderRequirementDetail={value}
                    index={index}
                  />
                );
              })
            : null}
          <div className="order-requirement-row">
            <SelectBoxComponent
              width="100%"
              require={true}
              onChange={setProgressStatus}
              isDisable={isDisable}
              placeholder={'Trạng thái đơn hàng'}
              value={progressStatus}
              data={lstStatus}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              width="100%"
              title="Phí vận chuyển"
              require={true}
              disabled={isDisable}
              value={shipFee}
              type="number"
              onChange={setShipFee}
            />
          </div>
          <div className="order-requirement-row">
            <Input
              width="100%"
              title="Giảm giá"
              require={true}
              disabled={isDisable}
              value={discount}
              type="number"
              onChange={setDiscount}
            />
          </div>
          <div className="order-requirement-row text-right">
            <span>Tổng tiền: </span>
            <span style={{ color: 'red' }}>{calcTotal()}</span>
          </div>
          <div className="order-requirement-row">
            {isDisable ? null : (
              <ButtonComponent icon="save" title={'LƯU'} onClick={onPutOrderRequirement} />
            )}
          </div>
        </div>
        {orderRequirementId > 0 ? (
          <Note
            objectId={orderRequirementId}
            functionId={EnumDisplayConfig.Danh_sach_dat_hang}
            recordUserId={userId}
          />
        ) : null}
      </div>
    </>
  );
};

export default OrderRequirementDetail;
