import { uniqueId } from 'lodash';
import React from 'react';
import { EnumDataType, OptionType } from 'src/api/models';
import { CSSProperties } from 'styled-components';
import './TableHeader.css';



export type TableColumnType = {
  code: string;
  title: string;
  dataType: EnumDataType;
  isOptions: boolean;
  options?: OptionType[];
  collumCss?: any;
  cellCss?: CSSProperties;
};

interface ITableHeader {
  header: TableColumnType[];
  allowCheckbox?: boolean;
  isCheckedAll?: boolean;
  isNo?: boolean;
}

const TableHeader: React.FC<ITableHeader> = (props) => {
  const {header, allowCheckbox, isCheckedAll, isNo} = props;

  return (
    <tr className="table-header-component">
      {isNo? <th key={`tableheaderno`} className="text-center">STT</th> : null}
      {allowCheckbox?  <th key={`tableheadercheckbox`} className="text-center">
        <i
          className={isCheckedAll? "fas fa-check check" : "fas fa-square"}
          onClick={() => {
            console.log("Click")
          }}
        />
      </th> : null}
      {header.map((column: TableColumnType, index: number) => {
        return (
          <th key={`tableheader${index}`} className="text-center">
            {column.title}
          </th>
        );
      })}
      <th/>
    </tr>
  );
};

export default TableHeader;
