import './TableRow.css';
import {
  EnumDataType,
} from 'src/api/models';
import React, { useEffect } from 'react';
import Input from 'src/components/Input/Input';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { TableColumnType } from '../TableHeader/TableHeader';
import { numberFormat } from 'src/utils/numberUtils';
import useLongPress from 'src/hooks/useLongPress';

export interface ITableRow<T extends {[code: string]: any}> {
  header: TableColumnType[];
  data: T;index: number;

  isShowContext: boolean;
  isInput: boolean;

  onRightMouseClick: (...args: any[]) => any;
  onDoubleClick: (...args: any[]) => any;
  onClickCheckbox?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onSelect?: () => void;
  highlight: boolean;
  allowCheckbox?: boolean;
  isChecked?: boolean;
  isNo?: boolean;
}

const TableRow = <T extends {[code: string]: any}, >(props: ITableRow<T>) => {
  const { 
    header,
    data,
    index,

    isInput,
    
    onRightMouseClick,
    onDoubleClick,
    onClickCheckbox,
    onClick,
    onSelect,
    highlight,
    allowCheckbox,
    isChecked,
    isNo
  } = props;const getValueElement = (value: any, column: TableColumnType) => {

    if(isInput) {
      <Input
        value={data[column.code]}
        disabled={false}
      />


    } else {
      if(column.isOptions) {
        const option = column.options.find(o => o.value == value);
        return <div className={'label-table'} style={option.css??undefined}>{option.title}</div>
      } else {
        switch(column.dataType) {
          case EnumDataType.Boolean:
            return <div className={`label-table ${column.cellCss? '' : 'text-center'} ${value? 'color-blue' : 'color-red'}`} style={column.cellCss??undefined}>
              {value? '✓' : '🗙'}
            </div>;
          case EnumDataType.BigInt:
          case EnumDataType.Int:
          case EnumDataType.Month:
          case EnumDataType.QuarterOfYear:
            return <div className={`label-table ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{numberFormat(value)}</div>
          case EnumDataType.Date:
            return <div className={`label-table ${column.cellCss? '' : 'text-center'}`} style={column.cellCss??undefined}>{value}</div>
          default:
            return <div className={'label-table'} style={column.cellCss??undefined}>{value}</div>
        }
      }
    }
  }


  const onLongPressProps = useLongPress((e: any) => {
    onSelect();
    onDoubleClick(data);
  })

  //End of function
  return (
    <tr className={`table-row-component ${highlight? 'highlight' : index % 2 == 0 ? 'even-row' : 'odd-row'}`} id={`row_${index}`}
      onMouseDown={(e) => {
        const RIGHT_MOUSE_BUTTON = 2;
        onSelect();
        if (e.button === RIGHT_MOUSE_BUTTON) onRightMouseClick(e, data)
      }}
      onDoubleClick={(e) => {
        onSelect();
        onDoubleClick(data);
      }}
      onClick={(e) => { 
        onSelect();
        if(onClick) onClick(data); 
      }}
      
      onTouchEnd={(e) => {
        onSelect();
        if(onClick) onClick(data); 
      }}
    >
      {isNo? <td className={`first-column text-center`}>{index + 1}</td> : null}
      {allowCheckbox ? (
        <td className={`table-row-checkbox`}>
          <div className="table-row-btn-check">
            <i className={isChecked? "fas fa-check check" : "fas fa-square"}
                onClick={() => {
                  onClickCheckbox(data, isChecked);
                }}
            />
          </div>
        </td>
      ) : null}

      {header.map((column: TableColumnType, index: number) => {
        return (
          <td key={`tablecell${index}`} 
            className={`column `}
            style={column.cellCss? column.cellCss : undefined}
          >
            {getValueElement(eval(`data.${column.code}`), column)}
           
          </td>
        );
      })}

      <td className={`last-column`}/>
    </tr>
  );
};

export default TableRow;
