import React, { useCallback, useEffect, useState } from 'react';
import { Navigate , useNavigate  } from 'react-router-dom';
import { useVerify } from 'src/api/backend-api';
import Card from 'src/components/Card';
import { StyledButton, StyledCardTitle } from 'src/components/Form';
import Input from 'src/components/Input';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import useProfile from 'src/hooks/useProfile';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup } from 'src/state/application/hooks';

const Verify: React.FC = () => {
  const profile = useProfile();
  const addPopup = useAddPopup();
  const navigate = useNavigate();
  const query = useQuery();
  const verifyOtp = useVerify();
  const [loginName, setLoginName] = useState<string | undefined>(undefined);
  const [otp, setOtp] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loginName = query?.get('loginName');
    if (loginName) {
      setLoginName(loginName);
    }
  }, [query]);

  const onVerify = useCallback(() => {
    // TODO: Validate
    setLoading(true);
    verifyOtp(loginName, otp)
      .then(() => {
        setLoading(false);
        navigate('/profile');
      })
      .catch((error) => {
        setLoading(false);
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      });
  }, [verifyOtp, loginName, otp, history, addPopup]);

  return profile ? (
    <Navigate to="/" />
  ) : (
    <Page>
      <Card width="450px">
        <StyledCardTitle>Xác nhận tài khoản</StyledCardTitle>
        <Input onChange={setOtp} value={otp} placeholder="OTP" />
        <StyledButton onClick={onVerify}>{loading && <Loading />}Xác nhận</StyledButton>
      </Card>
    </Page>
  );
};

export default Verify;
