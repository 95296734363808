import './OrderRequirement.css';
import { MouseEvent, useEffect, useState } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import {
  EnumAction,
  EnumDataType,
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  EventButton,
  OrderRequirementType,
} from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Table from 'src/components/Table/Table';
import {
  useDeleteOrderRequirement,
  useDownLoadOrderRequirementExcel,
  useGetOrderRequirement,
  usePrintOrderRequirement,
  usePutUpdateOrderRequirementProgressStatus,
} from 'src/api/orderRequirementApi';
import { useNavigate } from 'react-router-dom';
import { BASE_WEB_URL } from 'src/constants';
import useQuery from 'src/hooks/useQuery';
import UpdateStatus from '../../components/UpdateStatus/UpdateStatus';
import { uniqueId } from 'lodash';
import Printed from 'src/components/Printed/Printed';
import useModal from 'src/hooks/useModal';
import useDebounce from 'src/hooks/useDebounce';
import useSlideBar from 'src/hooks/useSlideBar';
import OrderRequirementDetail from './OrderRequirementDetail/OrderRequirementDetail';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

const OrderRequirement: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const lstStatus = [
    {
      value: EnumOrderRequirementProgressStatus.Waiting,
      title: EnumOrderRequirementProgressStatusTitle.Waiting,
      css: { color: '#3a3131' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Sent,
      title: EnumOrderRequirementProgressStatusTitle.Sent,
      css: { color: 'blue' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Received,
      title: EnumOrderRequirementProgressStatusTitle.Received,
      css: { color: 'green' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Error,
      title: EnumOrderRequirementProgressStatusTitle.Error,
      css: { color: 'red' },
    },
  ];

  const [progressStatus, setProgressStatus] = useState<EnumOrderRequirementProgressStatus>();
  const keywordDebound = useDebounce(keyword, 1000);
  const STATUS_FIELD_FILTER = 'value';
  const STATUS_FILTER = 'STATUS_FILTER';
  const filters = [
    {
      data: lstStatus,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
  ];

  const header = [
    {
      code: 'userName',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Tên người mua',
    },
    {
      code: 'receiverFullname',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Tên người nhận',
    },
    {
      code: 'progressStatus',
      dataType: EnumDataType.Text,
      isOptions: true,
      title: 'Trạng thái',
      options: lstStatus,
    },
    {
      code: 'shipFee',
      dataType: EnumDataType.Decimal,
      isOptions: false,
      title: 'Phí ship',
    },
    {
      code: 'discount',
      dataType: EnumDataType.Decimal,
      isOptions: false,
      title: 'Giảm giá',
    },
    {
      code: 'receiverZipCode',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Mã bưu điện',
    },
    {
      code: 'receiverAddress1',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Địa chỉ dòng 1',
    },
    {
      code: 'receiverAddress2',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Địa chỉ dòng 2',
    },
    {
      code: 'receiverAddress3',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Địa chỉ dòng 3',
    },
    {
      code: 'receiverAddress4',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Địa chỉ dòng 4',
    },
  ];

  //State
  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);

  const [rawData, setRawData] = useState<OrderRequirementType[]>([]);
  const [orderRequirements, setOrderRequirements] = useState<OrderRequirementType[]>([]);
  const [checkList, setCheckList] = useState<OrderRequirementType[]>([]);

  //Function
  const addPopup = useAddPopup();
  const getOrderRequirement = useGetOrderRequirement();
  const deleteOrderRequirementById = useDeleteOrderRequirement();
  const downLoadOrderRequirementExcel = useDownLoadOrderRequirementExcel();
  const printOrderRequirement = usePrintOrderRequirement();
  const putUpdateOrderRequirementProgressStatus = usePutUpdateOrderRequirementProgressStatus();

  const updateStatusModal = useModal(UpdateStatus);
  const printedModal = useModal(Printed);
  const slideBar = useSlideBar(OrderRequirementDetail);
  const confirmModal = useModal(ConfirmModal);

  const onClickCheckbox = (orderRequirement: OrderRequirementType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != orderRequirement);
      setCheckList(nCheckList);
      setOrderRequirements(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.orderRequirementId == i.orderRequirementId),
        ),
      );
    } else {
      setCheckList([...checkList, orderRequirement]);
      setOrderRequirements(orderRequirements.filter((o) => o != orderRequirement));
    }
  };

  const onClick = (orderRequirement: OrderRequirementType) => {
    console.log('Slide');
    slideBar.handlePresent({
      orderRequirementId: orderRequirement.orderRequirementId,
    });
  };
  //End of function

  //Menucontext
  const onView = (orderRequirementId: number) => {
    const url = `${BASE_WEB_URL}/order-requirement/${orderRequirementId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (orderRequirementId: number) => {
    const url = `${BASE_WEB_URL}/order-requirement/${orderRequirementId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (orderRequirementId: number) => {
    const onConfirm = () => {
      deleteOrderRequirementById(orderRequirementId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn hàng này?',
        listActionButton: listButton,
      },
      'XÓA ĐƠN HÀNG',
    );
  };

  const menuContext = (item: OrderRequirementType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.orderRequirementId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.orderRequirementId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.orderRequirementId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const validateCheckList = () => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn hàng',
        },
      });
      return false;
    } else {
      return true;
    }
  };

  //Toolbar

  const onUpdateOrderRequirementProgressStatus = (e: MouseEvent) => {
    if (validateCheckList()) {
      updateStatusModal.handlePresent(
        {
          listStatus: lstStatus,
          title: 'Trạng thái đơn hàng',
          postProcess: (status: EnumOrderRequirementProgressStatus) => {
            const ids = checkList.map((i) => i.orderRequirementId);
            updateStatusModal.handleDismiss();
            putUpdateOrderRequirementProgressStatus(ids, status)
              .then((data) => {
                addPopup({
                  txn: {
                    success: true,
                    summary: 'Cập nhật trạng thái thành công',
                  },
                });
                setReloadFlag(!reloadFlag);
              })
              .catch((error) => {
                addPopup({
                  error: {
                    title: 'Đã có lỗi xảy ra',
                    message: error.errorMessage,
                  },
                });
              });
          },
        },
        'CẬP NHẬT TRẠNG THÁI',
      );
    }
  };

  const onExport = (e: MouseEvent) => {
    if (validateCheckList()) {
      const orderRequirementIds = checkList.map((o) => o.orderRequirementId);
      downLoadOrderRequirementExcel(orderRequirementIds)
        .then((data) => {
          const url = window.URL.createObjectURL(data);
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute('download', 'DSDonHang.xlsx');
          tempLink.click();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const onPrint = (e: MouseEvent) => {
    if (validateCheckList()) {
      const orderRequirementIds = checkList.map((o) => o.orderRequirementId);
      printOrderRequirement(orderRequirementIds)
        .then((res) => {
          printedModal.handlePresent(
            {
              values: res,
            },
            'IN ĐƠN HÀNG',
          );
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const listButton: EventButton[] = [
    {
      name: 'Cập nhật trạng thái',
      icon: 'sync_alt',
      actionType: EnumAction.Edit,
      buttonClass: 'info order-tool-btn',
      action: onUpdateOrderRequirementProgressStatus,
      align: 'center',
    },

    {
      name: 'Export đơn hàng',
      icon: 'file_download',
      actionType: EnumAction.View,
      buttonClass: 'info order-tool-btn',
      action: onExport,
      align: 'center',
    },

    {
      name: 'In đơn hàng',
      icon: 'print',
      actionType: EnumAction.View,
      buttonClass: 'info order-tool-btn',
      action: onPrint,
      align: 'center',
    },
  ];
  //End of toolbar

  useEffect(() => {
    getOrderRequirement(keyword ?? '', page, size, progressStatus)
      .then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setOrderRequirements(
          data.items.filter(
            (i) => !checkList.some((c) => c.orderRequirementId == i.orderRequirementId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getOrderRequirement, keywordDebound, page, progressStatus, reloadFlag, size]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, [reloadFlag]);

  const onFilter = (res: { [filterKey: string]: any }) => {
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
  };

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH ĐẶT HÀNG'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />

      <Table
        header={header}
        isInput={false}
        checkedData={checkList}
        data={orderRequirements}
        menuContext={menuContext}
        allowCheckbox={true}
        onDoubleClick={(item) => onView(item.orderRequirementId)}
        onClickCheckbox={onClickCheckbox}
        onClick={onClick}
        isNo={true}
      />
    </>
  );
};

export default OrderRequirement;
