import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router';

import InfiniteScroll from 'react-infinite-scroll-component';
import './ContactList.css';
import { useGetUserList } from 'src/api/userApi';
import { useChat, useGetProfileInfo } from 'src/state/application/hooks';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import { ProfileInfo } from 'src/api/models';

interface IContactType {
  onSelect: (...args: any[]) => any;
}

const ContactList: React.FC<IContactType> = (props) => {
  const size = 20;

  //State
  const [contactList, setContactList] = useState<ProfileInfo[]>([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const profile = useGetProfileInfo();
  const getUserList = useGetUserList();
 
  const { defaultAvatar } = useConfiguration();

  const fetchData = (reset: boolean) => {
    const cpage = reset ? 1 : page;
    getUserList(keyword, size, cpage)
      .then((res) => {
        const nList = reset ? res.items : [...contactList, ...res.items];
        setContactList(nList);
        if (res.items.length < size) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage(cpage + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchData(true);
  }, [keyword]);

  //Main
  return (
    <div className={`contact-web-con show`} id="contact-popup">
      <input
        className="contact-search"
        type="text"
        value={keyword}
        onChange={(event) => {
          setKeyword(event.target.value);
        }}
      />
      <i className="fas fa-search contact-search-icon"></i>
      <div className="infinite-scr-con" id="contact-content">
        <InfiniteScroll
          dataLength={contactList.length}
          next={() => fetchData(false)}
          hasMore={hasMore}
          style={{ display: 'flex', flexDirection: 'column' }}
          scrollableTarget="contact-content"
          loader={<h4></h4>}
          endMessage={<p style={{ textAlign: 'center' }}></p>}
        >
          {contactList.map((value) => {
            if (value.userId == profile.info.userId) return null;
            return (
              <div
                className="contact-component"
                onClick={() => {
                  props.onSelect(value.userId);
                }}
              >
                <div className="contact-avt">
                  {value.avataUrl ? (
                    <img src={value.avataUrl} alt="avt" />
                  ) : (
                    <div className="contact-avt-null"></div>
                  )}
                </div>
                <div className="contact-content">
                  <div className="contact-title">
                    <div>{value.fullName}</div>
                  </div>
                  <div className="contact-main">
                    <div className="contact-value">{value.email}</div>
                  </div>
                </div>
              </div>
            );
          })}
          {contactList.length == 0 ? (
            <div className="contact-component">
              <div className="contact-avt">
                <img src={defaultAvatar} alt="avt" />
              </div>
              <div className="contact-content">
                <div className="contact-title">
                  <div>{'Chưa có liên hệ nào'}</div>
                </div>
                <div className="contact-main">
                  <div className="contact-value">
                    {'Vui lòng thêm liên hệ để có những cuộc trò chuyện thú vị cùng JVSCorp !'}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ContactList;
