import React, { useEffect, useState } from 'react';
import { useChat } from 'src/state/application/hooks';
import ContactList from '../ContactList/ContactList';

const ContactIcon: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  const chat = useChat();
  
  const onHiddenPopup = () => {
    setIsShow(false);
  };

  const openContact = (userId: number) => {
    chat(userId);
  };



  useEffect(() => {
    const handleClick = (event: any) => {
      const contactPopup = document.getElementById('contact-popup');
      const btnShowContact = document.getElementById('btn-show-contact');
      if (
        btnShowContact &&
        contactPopup &&
        !btnShowContact.contains(event.target) &&
        !contactPopup.contains(event.target)
      ) {
        setIsShow(false);
      }
    };

    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  //Main
  return (
    <div className="base-head-icon">
      <i
        className="fas fa-address-book"
        style={{ fontSize: 20 }}
        onClick={() => setIsShow(!isShow)}
        title="Danh sách người dùng"
        id="btn-show-contact"
      ></i>
      {isShow ? <ContactList onSelect={(userId: number) => {
        openContact(userId);
        onHiddenPopup();
      }} /> : null}
    </div>
  );
};
export default ContactIcon;
